import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_5/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(
      frontmatter: { language: { eq: "HU" }, title: { eq: "slide-4-5-0" } }
    ) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_5/4-5-1-bg__mobile.png" }) {
      childImageSharp {
        fixed(width: 1920) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    pilsudski: file(relativePath: { eq: "chapter_4/sub_5/pilsudski.png" }) {
      childImageSharp {
        fixed(width: 488) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    weygand: file(relativePath: { eq: "chapter_4/sub_5/weygand.png" }) {
      childImageSharp {
        fixed(width: 488) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    timelineTitels: mdx(
      frontmatter: { language: { eq: "HU" }, title: { eq: "timelines" } }
    ) {
      exports {
        titleArray {
          index
          position
          text
        }
      }
    }
    data1: allMdx(
      filter: {
        frontmatter: { language: { eq: "HU" }, type: { eq: "slide-map-1" } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          body
        }
      }
    }
    data2: allMdx(
      filter: {
        frontmatter: { language: { eq: "HU" }, type: { eq: "slide-map-2" } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          body
        }
      }
    }
    titlePilsudski: mdx(
      frontmatter: { language: { eq: "HU" }, title: { eq: "slide-4-5-0-1" } }
    ) {
      frontmatter{
        mobileTitle
      }
    }
    titleWeygand: mdx(
      frontmatter: { language: { eq: "HU" }, title: { eq: "slide-4-5-0-2" } }
    ) {
      frontmatter{
        mobileTitle
      }
    }
  }
`);

  return (
    <Template query={query} title="Szia V" buttonText="
    Jó választás! Kattints és figyeld a csata lefolyását."
      buttonText2="Ez a terv nem járna sikerrel. Nézd meg, hogy miért!" />
  );
};


export default Slide;
